import Vue from 'vue'

export default {
  state: {
    /**
     * Site info settings
     * @type {{
     *    brand_name: string,
     *    email_info: string,
     *    email_support: string,
     *    logo_file: string,
     *    css_url: string,
     *    registration_logo: string,
     *    registration_css_url: string,
     *    js_url: string,
     *    favicon_url: string
     * }}
     */
    settings: {},

    /**
     * Is registration available
     */
    available: true,

    /**
     * Other data
     */
    boolAnswers: [
      {
        id: true,
        label: 'Yes'
      }, {
        id: false,
        label: 'No'
      }
    ],
    genders: [],
    companyFileTypes: [],
    addressTypes: [],
    memberTypes: [],
    memberFileTypes: [],
    kycFoundSources: [],
    kycBusinessSectors: [],
    kycMoneyTurnover: [],
    kycPaymentsCount: [],
    counterpartyType: [],
    counterpartyFileType: [],
    addressConfirmationType: [],
    memberSourceFundsFileType: [],
    companyIndividualAddressConfirmationFileType: [],
    companyIndividualResidencePermitFileType: [],
    companyIndividualIDDocumentFileType: [],

    /**
     * @type {{terms_and_conditions: string}}
     */
    links: {},
    fileConditions: {
      extensions: ['pdf', 'jpg', 'jpeg', 'png']
    }
  },
  mutations: {
    setCountries (state, payload) {
      state.countries = payload
    },
    setGenders (state, payload) {
      state.genders = payload
    },
    setCompanyFileTypes (state, payload) {
      state.companyFileTypes = payload
    },
    setAddressTypes (state, payload) {
      state.addressTypes = payload
    },
    setMemberTypes (state, payload) {
      state.memberTypes = payload
    },
    setMemberFileTypes (state, payload) {
      state.memberFileTypes = payload
    },
    setKycFoundSources (state, payload) {
      state.kycFoundSources = payload
    },
    setKycBusinessSectors (state, payload) {
      state.kycBusinessSectors = payload
    },
    setKycMoneyTurnover (state, payload) {
      state.kycMoneyTurnover = payload
    },
    setKycPaymentsCount (state, payload) {
      state.kycPaymentsCount = payload
    },
    setCounterpartyType (state, payload) {
      state.counterpartyType = payload
    },
    setCounterpartyFileType (state, payload) {
      state.counterpartyFileType = payload
    },
    setAddressConfirmationTypes (state, payload) {
      state.addressConfirmationType = payload
    },
    setLinks (state, payload) {
      state.links = payload
    },
    setSettings (state, payload) {
      state.settings = payload
    },
    setAvailable (state, payload) {
      state.available = payload
    },
    setFileConditions (state, payload) {
      state.fileConditions = payload
    },
    setMemberSourceFundsFileType (state, payload) {
      state.memberSourceFundsFileType = payload
    },
    setWalletAddressConfirmationFileType (state, payload) {
      state.walletAddressConfirmationFileType = payload
    },
    setCompanyIndividualAddressConfirmationFileType (state, payload) {
      state.companyIndividualAddressConfirmationFileType = payload
    },
    setCompanyIndividualResidencePermitFileType (state, payload) {
      state.companyIndividualResidencePermitFileType = payload
    },
    setCompanyIndividualIDDocumentFileType (state, payload) {
      state.companyIndividualIDDocumentFileType = payload
    }
  },
  actions: {
    initApp ({ commit, dispatch }) {
      return Promise.all([
        Vue.$sdk.countries.list(),
        Vue.$sdk.registration.list(),
        Vue.$sdk.site.info(),
        Vue.$sdk.registration.files.conditions()
      ]).then(responses => {
        let response = null

        // countries.list()
        dispatch('setCountriesList', responses[0].data)

        // registration.list()
        response = responses[1].data

        commit('setGenders', response.memberGender)
        commit('setMemberTypes', response.memberType)
        commit('setCompanyFileTypes', response.companyFileType)

        commit('setAddressTypes', response.addressType)
        commit('setMemberFileTypes', response.memberFileType)
        commit('setKycFoundSources', response.kycFoundSources)
        commit('setKycBusinessSectors', response.kycBusinessSelector)
        commit('setKycMoneyTurnover', response.kycMoneyTurnover)
        commit('setKycPaymentsCount', response.kycPaymentsCount)
        commit('setCounterpartyType', response.counterpartyType)
        commit('setCounterpartyFileType', response.counterpartyFileType)
        commit('setAddressConfirmationTypes', response.memberAddressConfirmationFileType)
        commit('setMemberSourceFundsFileType', response.memberSourceFundsFileType)
        commit('setLinks', response.links)
        commit('setCompanyIndividualAddressConfirmationFileType', response.companyIndividualAddressConfirmationFileType)
        commit('setCompanyIndividualResidencePermitFileType', response.companyIndividualResidencePermitFileType)
        commit('setCompanyIndividualIDDocumentFileType', response.companyIndividualIDDocumentFileType)

        /**
         * site.info()
         * @type {{settings: object, available: object}}
         */
        const dataInfo = responses[2].data
        commit('setSettings', dataInfo.settings)

        if (dataInfo.available) {
          commit('setAvailable', dataInfo.available.reg)
        }

        commit('setFileConditions', responses[3].data)
      })
    }
  }
}
